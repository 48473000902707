import { gql } from '@apollo/client/core';

export const createBusinessModelByTypeMutation = gql`
  mutation createBusinessModelByTypeMutation(
    $object: business_model_insert_input!
  ) {
    insert_business_model_one(object: $object) {
      assumption
      companyId
      createdAt
      description
      id
      priority
      progress
      type
      updatedAt
      business_model_assignments {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
      business_model_visibilities {
        id
        businessModelId
        userId
        user {
          email
          firstname
          id
          createdAt
          lastname
          profilePhoto
        }
      }
    }
  }
`;

export const updateBusinessModelMutation = gql`
  mutation updateBusinessModelMutation(
    $type: business_model_type_enum!
    $companyId: Int!
    $set: business_model_set_input
  ) {
    update_business_model(
      where: { _and: { type: { _eq: $type }, companyId: { _eq: $companyId } } }
      _set: $set
    ) {
      returning {
        assumption
        assumptionData
        companyId
        createdAt
        description
        id
        priority
        progress
        type
        updatedAt
        business_model_assignments {
          id
          businessModelId
          userId
          user {
            email
            firstname
            id
            createdAt
            lastname
            profilePhoto
          }
        }
        business_model_visibilities {
          id
          businessModelId
          userId
          user {
            email
            firstname
            id
            createdAt
            lastname
            profilePhoto
          }
        }
      }
    }
  }
`;

export const addBusinessModelAccessMutation = gql`
  mutation addBusinessModelAccessMutation(
    $object: business_model_assignment_insert_input!
  ) {
    insert_business_model_assignment_one(object: $object) {
      id
      businessModelId
      userId
      user {
        email
        firstname
        id
        createdAt
        lastname
        profilePhoto
      }
    }
  }
`;

export const removeBusinessModelAccessMutation = gql`
  mutation removeBusinessModelAccessMutation($id: Int!) {
    delete_business_model_assignment_by_pk(id: $id) {
      businessModelId
      id
      userId
    }
  }
`;

export const updateBusinessModelVisibilityMutation = gql`
  mutation updateBusinessModelVisibilityMutation(
    $addVisibility: [business_model_visibility_insert_input!]!
    $removedVisibilities: [Int!]
  ) {
    insert_business_model_visibility(objects: $addVisibility) {
      returning {
        userId
        id
        businessModelId
        user {
          createdAt
          email
          firstname
          id
          lastname
          profilePhoto
        }
      }
    }
    delete_business_model_visibility(
      where: { id: { _in: $removedVisibilities } }
    ) {
      returning {
        id
        businessModelId
        createdAt
        updatedAt
        userId
      }
    }
  }
`;

export const addCustomerMutation = gql`
  mutation addCustomerMutation($object: customer_insert_input!) {
    insert_customer_one(object: $object) {
      valueProposition
      willingnessToPay
      updatedAt
      type
      solution
      problem
      priceSensitivity
      name
      description
      id
      createdAt
      category
      businessModelId
      customer_details(order_by: { createdAt: asc }) {
        title
        value
        id
        createdAt
      }
    }
  }
`;

export const updateCustomerMutation = gql`
  mutation updateCustomerMutation($id: Int!, $set: customer_set_input) {
    update_customer_by_pk(pk_columns: { id: $id }, _set: $set) {
      valueProposition
      willingnessToPay
      updatedAt
      type
      solution
      problem
      priceSensitivity
      name
      description
      id
      isDeleted
      createdAt
      category
      businessModelId
      customer_details(order_by: { createdAt: asc }) {
        title
        value
        id
        createdAt
      }
    }
  }
`;

export const addCustomerDetailMutation = gql`
  mutation addCustomerDetailMutation($object: customer_detail_insert_input!) {
    insert_customer_detail_one(object: $object) {
      id
      title
      value
      createdAt
      customerId
    }
  }
`;

export const updateCustomerDetailMutation = gql`
  mutation updateCustomerDetailMutation(
    $id: Int!
    $set: customer_detail_set_input
  ) {
    update_customer_detail_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      value
      title
      isDeleted
      createdAt
      customerId
    }
  }
`;

export const deleteCustomerDetailMutation = gql`
  mutation deleteCustomerDetailMutation($id: Int!) {
    delete_customer_detail_by_pk(idt: $id) {
      id
    }
  }
`;

export const addChannelMutation = gql`
  mutation addChannelMutation($object: channel_insert_input!) {
    insert_channel_one(object: $object) {
      id
    }
  }
`;

export const updateChannelMutation = gql`
  mutation updateChannelMutation(
    $channelId: Int!
    $objChannel: channel_set_input!
    $objTopic: [channel_topic_insert_input!]!
    $objCustomer: [channel_customer_insert_input!]!
  ) {
    update_channel_by_pk(pk_columns: { id: $channelId }, _set: $objChannel) {
      id
      name
      type
      topic
      topics {
        topic
        channelId
      }
      customers {
        customerId
        channelId
        customer {
          id
          category
          name
          type
        }
      }
      createdAt
      updatedAt
      efficiency
      customerId
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
    delete_channel_topic(where: { channelId: { _eq: $channelId } }) {
      affected_rows
    }
    insert_channel_topic(objects: $objTopic) {
      affected_rows
    }
    delete_channel_customer(where: { channelId: { _eq: $channelId } }) {
      affected_rows
    }
    insert_channel_customer(objects: $objCustomer) {
      affected_rows
    }
  }
`;

export const deleteChannelMutation = gql`
  mutation delete_channel($channel_id: Int!) {
    update_channel_by_pk(
      _set: { isDeleted: true }
      pk_columns: { id: $channel_id }
    ) {
      id
    }
  }
`;

export const addKeyResourceMutation = gql`
  mutation addKeyResourceMutation($object: key_resource_insert_input!) {
    insert_key_resource_one(object: $object) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateKeyResourceMutation = gql`
  mutation updateKeyResourceMutation(
    $keyResourceId: Int!
    $objKeyResource: key_resource_set_input!
    $objRel: [key_activities_resource_insert_input!]!
  ) {
    update_key_resource_by_pk(
      pk_columns: { id: $keyResourceId }
      _set: $objKeyResource
    ) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      resources_activities {
        key_resource_id
        key_activity_id
        key_activity {
          name
          id
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
    delete_key_activities_resource(
      where: { key_resource_id: { _eq: $keyResourceId } }
    ) {
      affected_rows
    }
    insert_key_activities_resource(objects: $objRel) {
      affected_rows
    }
  }
`;

export const deleteKeyResourceMutation = gql`
  mutation deleteKeyResourceMutation($id: Int!) {
    update_key_resource_by_pk(
      pk_columns: { id: $id }
      _set: { isDeleted: true }
    ) {
      name
    }
  }
`;

export const addKeyActivityMutation = gql`
  mutation addKeyActivityMutation($object: key_activity_insert_input!) {
    insert_key_activity_one(object: $object) {
      id
      name
      type
      createdAt
      updatedAt
      efficiency
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateKeyActivityMutation = gql`
  mutation insert_key_activity(
    $keyActivityId: Int!
    $objKeyActivity: key_activity_set_input!
    $objRel: [key_activity_channel_insert_input!]!
  ) {
    update_key_activity_by_pk(
      pk_columns: { id: $keyActivityId }
      _set: $objKeyActivity
    ) {
      id
      name
      type
      createdAt
      updatedAt
      efficiency
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      channels {
        channel_id
        key_activity_id
        channel {
          id
          name
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
    delete_key_activity_channel(
      where: { key_activity_id: { _eq: $keyActivityId } }
    ) {
      affected_rows
    }
    insert_key_activity_channel(objects: $objRel) {
      affected_rows
    }
  }
`;

export const deleteKeyActivityMutation = gql`
  mutation deleteKeyActivityMutation($id: Int!) {
    update_key_activity_by_pk(
      pk_columns: { id: $id }
      _set: { isDeleted: true }
    ) {
      name
    }
  }
`;

export const addKeyPartnerMutation = gql`
  mutation addKeyPartnerMutation($object: key_partner_insert_input!) {
    insert_key_partner_one(object: $object) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateKeyPartnerMutation = gql`
  mutation update_key_partner(
    $keyPartnerId: Int!
    $objKeyPartner: key_partner_set_input!
    $objRel: [key_partner_resources_insert_input!]!
  ) {
    update_key_partner_by_pk(
      pk_columns: { id: $keyPartnerId }
      _set: $objKeyPartner
    ) {
      id
      name
      type
      essence
      createdAt
      updatedAt
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      partner_resources {
        key_partner_id
        key_resource_id
        key_resource {
          id
          name
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
    delete_key_partner_resources(
      where: { key_partner_id: { _eq: $keyPartnerId } }
    ) {
      affected_rows
    }
    insert_key_partner_resources(objects: $objRel) {
      affected_rows
    }
  }
`;

export const deleteKeyPartnerMutation = gql`
  mutation deleteKeyPartnerMutation($id: Int!) {
    update_key_partner_by_pk(
      pk_columns: { id: $id }
      _set: { isDeleted: true }
    ) {
      name
    }
  }
`;

export const addRevenueMutation = gql`
  mutation addRevenueMutation($object: revenue_insert_input!) {
    insert_revenue_one(object: $object) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      frequency
      description
      actionPlan
      milestoneId
      businessModelId
      employeeResponsible
      revenue_customers {
        id
        customerId
        customer {
          name
          id
          category
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateRevenueMutation = gql`
  mutation updateRevenueMutation($id: Int!, $set: revenue_set_input) {
    update_revenue_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      frequency
      description
      milestoneId
      actionPlan
      businessModelId
      employeeResponsible
      revenue_customers {
        id
        customerId
        customer {
          name
          id
          category
        }
      }
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteRevenueMutation = gql`
  mutation deleteRevenueMutation($id: Int!) {
    delete_revenue_by_pk(id: $id) {
      id
    }
  }
`;

export const addRevenueCustomerMutation = gql`
  mutation addRevenueCustomerMutation($object: revenue_customer_insert_input!) {
    insert_revenue_customer_one(object: $object) {
      revenueId
      customerId
      id
      customer {
        id
        category
        name
        type
      }
    }
  }
`;

export const removeRevenueCustomerMutation = gql`
  mutation removeRevenueCustomerMutation($revenueId: Int!, $customerId: Int!) {
    delete_revenue_customer(
      where: {
        _and: [
          { customerId: { _eq: $customerId } }
          { revenueId: { _eq: $revenueId } }
        ]
      }
    ) {
      affected_rows
      returning {
        id
        customerId
        revenueId
      }
    }
  }
`;

export const addCostMutation = gql`
  mutation addCostMutation($object: cost_insert_input!) {
    insert_cost_one(object: $object) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      valueArray
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const updateCostMutation = gql`
  mutation updateCostMutation($id: Int!, $set: cost_set_input) {
    update_cost_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
      type
      value
      currency
      createdAt
      updatedAt
      valueArray
      description
      milestoneId
      businessModelId
      employeeResponsible
      actionPlan
      milestone {
        categoryId
        companyId
        createdAt
        date
        description
        id
        isDeleted
        scenarioId
        status
        title
        updatedAt
        calender_category {
          title
        }
        scenario {
          color
          title
        }
        metricType
        metricName
        showOnMarketPlan
        milestoneValue
        relatedGoalId
        color
        dateAchieved
      }
    }
  }
`;

export const deleteCostMutation = gql`
  mutation deleteCostMutation($id: Int!) {
    delete_cost_by_pk(id: $id) {
      id
    }
  }
`;

export const updateMultipleCostsMutation = gql`
  mutation updateMultipleCostsMutation($ids: [Int!], $set: cost_set_input) {
    update_cost(where: { id: { _in: $ids } }, _set: $set) {
      returning {
        id
        type
        value
        currency
        createdAt
        updatedAt
        valueArray
        description
        milestoneId
        actionPlan
        businessModelId
        employeeResponsible
        milestone {
          categoryId
          companyId
          createdAt
          date
          description
          id
          isDeleted
          scenarioId
          status
          title
          updatedAt
          calender_category {
            title
          }
          scenario {
            color
            title
          }
          metricType
          metricName
          showOnMarketPlan
          milestoneValue
          relatedGoalId
          color
          dateAchieved
        }
      }
    }
  }
`;
