<template>
  <div class="footer_menu">
    <div
      class="footer_menu__item"
      @click="$router.push({ path: '/app/explore' })"
    >
      <i class="fa-solid fa-house"></i>
      <span>Home</span>
    </div>
    <div
      class="footer_menu__item"
      @click="$router.push({ path: '/app/growth-framework' })"
    >
      <i class="fa-solid fa-business-time"></i>
      <span>Business</span>
    </div>
    <div
      class="footer_menu__item"
      @click="$router.push({ path: '/app/events' })"
    >
      <i class="fa-solid fa-calendar"></i>
      <span>Events</span>
    </div>
    <div
      class="footer_menu__item"
      @click="$router.push({ path: '/app/messaging' })"
    >
      <i class="fa-solid fa-message"></i>
      <span>Messaging</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMenu',
};
</script>

<style scoped>
.footer_menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px 10px;
  height: 50px;
  background: #f2f2f2;
  max-width: 400px;
  min-width: 100%;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  .footer_menu__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #8e8e93;
    cursor: pointer;
    margin-left: 15px;

    svg {
      font-size: 20px;
    }

    span {
      font-weight: 600;
    }

    /* hover */
    &:hover {
      color: #e5724e;

      svg {
        color: #e5724e;
      }

      span {
        color: #e5724e;
      }
    }
  }
}
</style>
