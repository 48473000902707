<template>
  <c-modal
    :is-open="isEventModalOpen"
    :on-close="closeCreateEventModal"
    :is-centered="true"
    size="3xl"
  >
    <c-modal-content ref="content">
      <form @submit.prevent="onEventFormSubmit">
        <c-modal-header>{{
          editing ? 'Edit Event' : 'Create Event'
        }}</c-modal-header>
        <c-modal-close-button @click.prevent="closeCreateEventModal" />
        <c-box class="modal-contents">
          <c-modal-body h="600px">
            <div
              style="position: relative"
              v-if="imageUrl || (editing && eventForm.thumb_nail)"
            >
              <c-image
                :src="editing === true ? eventForm.thumb_nail : imageUrl"
                h="350px"
                w="full"
                borderRadius="8px"
                alt="event-thumbnail"
                objectFit="cover"
              >
              </c-image>
              <c-button
                @click="openFileInput"
                style="position: absolute; top: 10px; right: 10px"
                variant-color="vc-orange"
                aria-label="Send email"
              >
                <c-icon name="pen" />
              </c-button>
              <input
                type="file"
                ref="fileInput"
                @change="onFileChange"
                style="display: none"
              />
            </div>
            <c-box
              v-else
              @click="openFileInput"
              style="position: relative"
              h="350px"
              w="100%"
              rounded="lg"
              objectFit="cover"
              alt="back"
              ref="event-banner"
              v-chakra="{
                backgroundColor: '#C7D6F466',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }"
            >
              <input
                type="file"
                ref="fileInput"
                @change="onFileChange"
                style="display: none"
              />
              <c-spinner
                v-if="isUploadingImage"
                color="#fff"
                size="xl"
                thickness="4px"
              />
              <c-flex
                v-else
                flexDirection="column"
                align="center"
                justify="center"
              >
                <c-icon color="gray.500" name="images" size="24" />
                <c-text fontSize="lg" fontWeight="700"
                  >Upload Cover Image</c-text
                >
                <c-text fontSize="md"
                  >Prefered Aspect Ratio is 16:9, minimum width is 500
                  pixels</c-text
                >
              </c-flex>
            </c-box>
            <c-form-control class="form-labels" mt="4">
              <c-form-label color="#002B49">Event Type</c-form-label>
              <c-radio-group
                variant-color="orange"
                value="online"
                v-model="$v.eventForm.type.$model"
              >
                <c-radio value="VIRTUAL">Online</c-radio>
                <c-radio value="PHYSICAL">In-Person</c-radio>
              </c-radio-group>
            </c-form-control>
            <c-form-control mt="4">
              <c-form-label class="form-labels">Event Name</c-form-label>
              <c-input
                style="background-color: rgba(199, 214, 244, 0.4)"
                v-model="$v.eventForm.name.$model"
                variant="filled"
                placeholder="Event Name"
              />
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                type="text"
                my="1"
                v-if="$v.eventForm.name.$dirty && !$v.eventForm.name.required"
              >
                Event name is required
              </c-text>
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                my="1"
                v-if="$v.eventForm.name.$dirty && !$v.eventForm.name.minLength"
              >
                Event name should be a minimum of 3 characters
              </c-text>
            </c-form-control>
            <c-form-control mt="4">
              <c-form-label class="form-labels">Timezone</c-form-label>
              <c-box h="50px">
                <app-select
                  :reduce="(timez) => timez.label"
                  :full-width="true"
                  :searchable="false"
                  :filled="true"
                  placeholder="Select Timezone for Event"
                  :options="timeZones"
                  v-model="$v.eventForm.time_zone.$model"
                  e
                />
              </c-box>
            </c-form-control>

            <div class="grid-container mt-4">
              <div class="grid-item-event">
                <AppDatePicker
                  format="YYYY-MM-DD"
                  type="date"
                  v-model="$v.eventForm.start_date.$model"
                  :max-date="$v.eventForm.end_date.$model"
                >
                  <template v-slot:input>
                    <c-form-control>
                      <c-form-label class="form-labels"
                        >Start date</c-form-label
                      >
                      <c-input
                        style="background-color: rgba(199, 214, 244, 0.4)"
                        size="lg"
                        h="40px"
                        borderRadius="5px"
                        variant="filled"
                        color="gray.400"
                        placeholder="Select start date"
                        :value="startDateFormatted"
                      ></c-input>
                    </c-form-control>
                  </template>
                </AppDatePicker>
              </div>
              <div class="grid-item-event">
                <c-form-control>
                  <c-form-label class="form-labels">Start time</c-form-label>
                  <div style="width: 100%">
                    <v-timepicker
                      input-width="100%"
                      :input-class="['time-picker-style']"
                      autocomplete="on"
                      manual-input
                      format="hh:mm A"
                      v-model="startTimeFormatted"
                    />
                  </div>
                </c-form-control>
              </div>
            </div>
            <div class="grid-container mt-4">
              <div class="grid-item-event">
                <AppDatePicker
                  format="YYYY-MM-DD"
                  type="date"
                  v-model="$v.eventForm.end_date.$model"
                  :min-date="$v.eventForm.start_date.$model"
                >
                  <template v-slot:input>
                    <c-form-control>
                      <c-form-label class="form-labels">End date</c-form-label>
                      <c-input
                        style="background-color: rgba(199, 214, 244, 0.4)"
                        size="lg"
                        h="40px"
                        borderRadius="5px"
                        variant="filled"
                        color="gray.400"
                        placeholder="Select start date"
                        v-model="endDateFormatted"
                      ></c-input>
                    </c-form-control>
                  </template>
                </AppDatePicker>
              </div>
              <div class="grid-item-event">
                <c-form-control>
                  <c-form-label class="form-labels">End time</c-form-label>
                  <div style="width: 100%">
                    <v-timepicker
                      input-width="100%"
                      autocomplete="on"
                      manual-input
                      format="hh:mm A"
                      v-model="endTimeFormatted"
                    />
                  </div>
                </c-form-control>
              </div>
            </div>
            <c-form-control v-if="eventForm.type === 'VIRTUAL'" mt="4">
              <c-form-label class="form-labels">Meeting Link</c-form-label>
              <c-input
                style="background-color: rgba(199, 214, 244, 0.4)"
                v-model="$v.eventForm.meeting_link.$model"
                variant="filled"
                placeholder="Meeting Link"
              />
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                my="1"
                v-if="
                  $v.eventForm.meeting_link.$dirty &&
                  !$v.eventForm.meeting_link.minLength
                "
              >
                Meeting link is required
              </c-text>
              <c-text
                color="vc-orange.400"
                fontSize="xs"
                my="1"
                v-if="$v.eventForm.name.$dirty && !$v.eventForm.name.minLength"
              >
                Event link should be a minimum of 3 characters
              </c-text>
            </c-form-control>
            <c-form-control mt="4">
              <c-form-label class="form-labels">Description</c-form-label>
              <c-textarea
                type="tel"
                h="120px"
                borderRadius="8px"
                variant="filled"
                ref="initialRef"
                v-model="$v.eventForm.description.$model"
                :value="$v.eventForm.description.$model"
                placeholder="Address"
              />
            </c-form-control>

            <c-form-control mt="4">
              <c-flex justify="space-between" align="center">
                <c-form-label class="form-labels">Speakers</c-form-label>
                <c-flex fontSize="12px"
                  >Not on this platform<c-switch
                    color="orange"
                    size="sm"
                    ml="2"
                    v-model="notOnPlatform"
                /></c-flex>
              </c-flex>
              <!--              <c-button @click.p.prevent="openSpeakerModal" size="xs">Add connections</c-button>-->
              <p
                style="
                  font-size: 12px;
                  background-color: rgba(128, 128, 128, 0.46);
                  margin-bottom: 10px;
                "
                v-if="notOnPlatform"
              >
                For speakers not on this platform
              </p>
              <c-flex
                p="3"
                borderRadius="8px"
                v-if="eventSpeakerNotOnVB > 0 || notOnPlatform"
                mt="4"
                mb="4"
                h="100px"
                bg="#C7D6F466"
              >
                <c-flex
                  :key="item.id"
                  v-for="item in eventSpeakerNotOnVB"
                  style="
                    margin-right: 10px;
                    background-color: rgba(255, 69, 0, 0.38);
                    height: 25px;
                    border-radius: 3px;
                  "
                >
                  <c-text
                    p="3px"
                    fontSize="15px"
                    fontWeight="500"
                    color="vc-orange.600"
                    >{{ item.firstName }} {{ item.lastName }}</c-text
                  >
                  <c-button
                    @click="removeSpeaker(item)"
                    variant="ghost"
                    color="vc-orange.600"
                    right-icon="close"
                    size="xs"
                  />
                </c-flex>
              </c-flex>
              <form
                v-if="notOnPlatform"
                @submit.prevent="addSpeaker"
                class="grid-container"
              >
                <div class="grid-item-event">
                  <c-input
                    v-model="$v.speakerForm.firstName.$model"
                    size="lg"
                    h="40px"
                    type="text"
                    borderRadius="5px"
                    variant="filled"
                    color="gray.400"
                    placeholder="Enter speaker first name"
                  ></c-input>
                  <c-text
                    color="vc-orange.400"
                    fontSize="xs"
                    my="1"
                    v-if="
                      $v.speakerForm.firstName.$dirty &&
                      !$v.speakerForm.firstName.required
                    "
                  >
                    First name is required
                  </c-text>
                  <c-text
                    color="vc-orange.400"
                    fontSize="xs"
                    my="1"
                    v-if="
                      $v.speakerForm.firstName.$dirty &&
                      !$v.speakerForm.firstName.minLength
                    "
                  >
                    First name should be a minimum of 3 characters
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-input
                    v-model="$v.speakerForm.lastName.$model"
                    size="lg"
                    h="40px"
                    type="text"
                    borderRadius="5px"
                    variant="filled"
                    color="gray.400"
                    placeholder="Enter speaker last name"
                  ></c-input>
                  <c-text
                    color="vc-orange.400"
                    fontSize="xs"
                    my="1"
                    v-if="
                      $v.speakerForm.lastName.$dirty &&
                      !$v.speakerForm.lastName.required
                    "
                  >
                    Last name is required
                  </c-text>
                  <c-text
                    color="vc-orange.400"
                    fontSize="xs"
                    my="1"
                    v-if="
                      $v.speakerForm.lastName.$dirty &&
                      !$v.speakerForm.lastName.minLength
                    "
                  >
                    Last name should be a minimum of 3 characters
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-input
                    v-model="$v.speakerForm.companyName.$model"
                    size="lg"
                    h="40px"
                    borderRadius="5px"
                    variant="filled"
                    color="gray.400"
                    placeholder="Enter Company Name"
                  ></c-input>
                  <c-text
                    color="vc-orange.400"
                    fontSize="xs"
                    my="1"
                    v-if="
                      $v.speakerForm.companyName.$dirty &&
                      !$v.speakerForm.companyName.required
                    "
                  >
                    Speaker Company is required
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-input
                    v-model="$v.speakerForm.position.$model"
                    size="lg"
                    h="40px"
                    borderRadius="5px"
                    variant="filled"
                    color="gray.400"
                    placeholder="Enter Position"
                  ></c-input>
                  <c-text
                    color="vc-orange.400"
                    fontSize="xs"
                    my="1"
                    v-if="
                      $v.speakerForm.position.$dirty &&
                      !$v.speakerForm.position.required
                    "
                  >
                    Position is required
                  </c-text>
                </div>
                <div class="grid-item-event">
                  <c-button variant-color="vc-orange" type="submit">
                    Add Speaker
                  </c-button>
                </div>
              </form>
              <p
                style="
                  font-size: 12px;
                  background-color: rgba(128, 128, 128, 0.46);
                  margin-bottom: 10px;
                  margin-top: 20px;
                "
              >
                For speakers on this platform
              </p>
              <div
                style="margin-bottom: 10px"
                v-if="selectedEventSpeakers.length"
              >
                <c-flex
                  justify="space-between"
                  style="
                    margin-top: 10px;
                    background: rgba(255, 77, 0, 0.1);
                    display: flex;
                    color: orangered;
                    padding: 5px;
                    border-radius: 5px;
                    width: 100%;
                    height: 60px;
                  "
                  :key="
                    person.id +
                    (person.companyName)
                  "
                  v-for="person in selectedEventSpeakers"
                >
                  <div style="display: flex">
                    <img
                      v-if="person.profilePhoto"
                      alt="user-avatar"
                      width="45px"
                      height="45px"
                      :src="person.profilePhoto"
                    />
                    <img
                      v-else
                      alt="user-avatar"
                      width="45px"
                      height="45px"
                      src="@/assets/img/user.jpg"
                    />
                    <div style="display: block; margin-left: 10px">
                      <c-text fontWeight="600" fontSize="17px"
                        >{{ person.firstName ? person.firstName : person.firstname }} {{ person.lastName ? person.lastName : person.lastname }}</c-text
                      >
                      <span>
                        {{
                          person.companyName
                        }}
                      </span>
                    </div>
                  </div>
                  <c-icon-button
                    ml="5px"
                    aria-label="Remove tagged user"
                    variant="ghost"
                    variant-color="orange"
                    size="xs"
                    icon="close"
                    @click="removeSelectedEventSpeaker(person)"
                  />
                </c-flex>
              </div>
              <div class="search_input">
                <c-input-group>
                  <c-input-left-element>
                    <i class="fas fa-search"></i>
                  </c-input-left-element>
                  <c-input
                    :value="query"
                    @input.native="handleInput"
                    placeholder="Search speakers on vibrant creator..."
                    borderWidth="0"
                    borderRadius="20"
                    size="lg"
                    variant="filled"
                    type="text"
                  />
                </c-input-group>
              </div>
              <c-box class="modal-contents">
                <div style="text-align: center" v-if="loading">
                  <c-spinner ml="2" variant-color="orange" thickness="2px" />
                </div>
                <div v-else>
                  <c-box
                    @click="selected(item)"
                    v-for="item in allUsers"
                    :key="
                      item.id + `${item.companyName}`
                    "
                  >
                    <tagged-person-card :person="item" />
                  </c-box>
                </div>
              </c-box>
            </c-form-control>
          </c-modal-body>
        </c-box>
        <c-modal-footer>
          <c-button
            v-if="!editing"
            type="submit"
            variant-color="vc-orange"
            w="100%"
            h="50px"
            :disabled="$v.eventForm.$invalid || isCreatingEvent"
          >
            <c-spinner v-if="isCreatingEvent" color="#fff" thickness="3px" />
            <c-text v-else color="#fff">Save</c-text>
          </c-button>
          <c-button
            v-if="editing"
            @click="updateEventData"
            variant-color="vc-orange"
            w="100%"
            h="50px"
            :disabled="$v.eventForm.$invalid || loading"
            :loading="loading"
          >
            <c-spinner v-if="isCreatingEvent" color="#fff" thickness="3px" />
            <c-text v-else color="#fff">Update</c-text>
          </c-button>
        </c-modal-footer>
      </form>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import AppDatePicker from '@/views/App/components/AppDatePicker.vue';
import AppSelect from '@/views/App/components/AppSelect.vue';
import { EventBus } from '@/eventBus';
import TaggedPersonCard from '@/views/App/Explore/components/tagged/TaggedPersonCard.vue';
import { required, minLength } from 'vuelidate/lib/validators';
import { backgroundImage } from 'tailwindcss/lib/plugins';
import { uploadFile } from '@/services/common';
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { searchUsers } from '@/services/insight';
// import { getUsers } from '@/services/user';
import { updateEvent } from '@/services/event';

const eventTypeLink = {
  VIRTUAL: 'online',
  PHYSICAL: 'in-person',
};
export default {
  name: 'EventModal',
  components: {
    AppDatePicker,
    AppSelect,
    TaggedPersonCard,
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),

    ...mapGetters({
      selectedEventSpeakers: 'event/getSelectedEventSpeakers',
    }),

    eventSpeakerNotOnVB() {
      return this.eventForm.event_speakers.filter((es) => !es.user);
    },

    startDateFormatted() {
      return this.eventForm.start_date
        ? this.$moment(this.eventForm.start_date).format('YYYY-MM-DD')
        : ''; // Return empty string if no date is selected
    },

    endDateFormatted() {
      return this.eventForm.end_date
        ? this.$moment(this.eventForm.end_date).format('YYYY-MM-DD')
        : ''; // Return empty string if no date is selected
    },
    startTimeFormatted: {
      get() {
        return this.formatTimeString(this.eventForm.start_time);
      },
      set(value) {
        this.eventForm.start_time = this.parseTimeString(value); // Parse back to raw time if needed
      },
    },
    endTimeFormatted: {
      get() {
        return this.formatTimeString(this.eventForm.end_time);
      },
      set(value) {
        this.eventForm.end_time = this.parseTimeString(value); // Parse back to raw time if needed
      },
    },
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allUsers: [],
      query: '',
      eventDate: '',
      editing: false,
      loading: false,
      isCreatingEvent: false,
      imageUrl: '',
      isUploadingImage: false,
      notOnPlatform: false,
      eventForm: {
        name: '',
        start_time: '00:00 AM',
        description: '',
        type: 'PHYSICAL',
        end_time: '00:00 PM',
        start_date: '',
        end_date: '',
        event_speakers: [],
        meeting_link: '',
        time_zone: '',
      },
      speakerForm: {
        firstName: null,
        lastName: null,
        companyName: null,
        position: null,
      },
      showSpeakers: false,
      isEventModalOpen: false,
      timeZones: [
        {
          label: '(GMT-12:00) International Date Line West',
          value: 'Etc/GMT+12',
        },
        { label: '(GMT-11:00) Midway Island, Samoa', value: 'Pacific/Midway' },
        { label: '(GMT-10:00) Hawaii', value: 'Pacific/Honolulu' },
        { label: '(GMT-09:00) Alaska', value: 'America/Anchorage' },
        {
          label: '(GMT-08:00) Pacific Time (US & Canada)',
          value: 'America/Los_Angeles',
        },
        {
          label: '(GMT-07:00) Mountain Time (US & Canada)',
          value: 'America/Denver',
        },
        {
          label: '(GMT-06:00) Central Time (US & Canada)',
          value: 'America/Chicago',
        },
        {
          label: '(GMT-05:00) Eastern Time (US & Canada)',
          value: 'America/New_York',
        },
        {
          label: '(GMT-04:00) Atlantic Time (Canada)',
          value: 'America/Halifax',
        },
        {
          label: '(GMT-03:00) Buenos Aires, Georgetown',
          value: 'America/Argentina/Buenos_Aires',
        },
        { label: '(GMT-02:00) Mid-Atlantic', value: 'Etc/GMT+2' },
        {
          label: '(GMT-01:00) Azores, Cape Verde Islands',
          value: 'Atlantic/Azores',
        },
        {
          label: '(GMT) Greenwich Mean Time: Dublin, Lisbon, London',
          value: 'Europe/London',
        },
        {
          label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
          value: 'Europe/Berlin',
        },
        {
          label: '(GMT+02:00) Athens, Bucharest, Istanbul',
          value: 'Europe/Athens',
        },
        {
          label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
          value: 'Europe/Moscow',
        },
        { label: '(GMT+04:00) Abu Dhabi, Muscat', value: 'Asia/Dubai' },
        { label: '(GMT+04:30) Kabul', value: 'Asia/Kabul' },
        {
          label: '(GMT+05:00) Islamabad, Karachi, Tashkent',
          value: 'Asia/Karachi',
        },
        {
          label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
          value: 'Asia/Kolkata',
        },
        { label: '(GMT+05:45) Kathmandu', value: 'Asia/Kathmandu' },
        { label: '(GMT+06:00) Astana, Dhaka', value: 'Asia/Dhaka' },
        { label: '(GMT+06:30) Yangon (Rangoon)', value: 'Asia/Yangon' },
        { label: '(GMT+07:00) Bangkok, Hanoi, Jakarta', value: 'Asia/Bangkok' },
        {
          label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
          value: 'Asia/Shanghai',
        },
        { label: '(GMT+09:00) Seoul', value: 'Asia/Seoul' },
        { label: '(GMT+09:30) Adelaide', value: 'Australia/Adelaide' },
        {
          label: '(GMT+10:00) Brisbane, Canberra, Melbourne, Sydney',
          value: 'Australia/Sydney',
        },
        {
          label: '(GMT+11:00) Magadan, Solomon Islands, New Caledonia',
          value: 'Asia/Magadan',
        },
        {
          label: '(GMT+12:00) Auckland, Wellington',
          value: 'Pacific/Auckland',
        },
        { label: "(GMT+13:00) Nuku'alofa", value: 'Pacific/Tongatapu' },
      ],
    };
  },
  validations: {
    eventForm: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
      meeting_link: {
        minLength: minLength(3),
      },
      start_time: {
        required,
      },
      end_time: {
        required,
      },
      type: {
        required,
      },
      start_date: {
        required,
      },
      end_date: {
        required,
      },
      time_zone: {
        required,
      },
    },

    speakerForm: {
      firstName: {
        required,
        minLength: minLength(3),
      },
      lastName: {
        required,
        minLength: minLength(3),
      },
      companyName: {
        required,
      },
      position: {
        required,
      },
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    formatTimeString(time) {
      if (!time) return '';
      const [hourString, minuteString] = time.split(':');
      let hour = parseInt(hourString, 10);
      const minute = minuteString.split(' ')[0];
      const period = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12 || 12;
      const formattedHour = String(hour).padStart(2, '0'); // Ensures 2-digit hour
      const formattedMinute = String(minute).padStart(2, '0'); // Ensures 2-digit minute
      return `${formattedHour}:${formattedMinute} ${period}`;
    },

    parseTimeString(formattedTime) {
      const [time, period] = formattedTime.split(' ');
      let [hour, minute] = time.split(':').map(Number);
      if (period === 'PM' && hour < 12) hour += 12;
      if (period === 'AM' && hour === 12) hour = 0;
      return `${hour}:${minute}`;
    },
    getEventType(typ) {
      return eventTypeLink[typ];
    },
    cleanTime(tmp_time) {
      return tmp_time.split(':');
    },
    selected(person) {
      const alreadySelected = this.selectedEventSpeakers.some((speaker) => {
        console.log("perxon", person);
        console.log("Speaxer", speaker);
        return (
          speaker.id + (speaker.companyName) ===
          person.id + (person.companyName)
        );
      });

      if (!alreadySelected) {
        if (this.editing) {
          console.log("Fixable=>", person);
          EventBus.$emit('add-on-speakers', {
            ...person,
            user: { ...person },
          });
        }

        this.$store.dispatch('event/selectEventSpeaker', {
          ...person
        });

        this.allUsers = this.allUsers.filter((usr) => {
          return (
            usr.id + (usr.companyName) !==
            person.id +
              (person.companyName)
          );
        });
      }
    },
    removeSelectedEventSpeaker(person) {
      if (this.editing) {
        EventBus.$emit('remove-on-speakers', person);
        this.eventForm.event_speakers = this.eventForm.event_speakers.filter(
          (speaker) =>
            speaker.id + speaker.companyName !==
            person.id + (person.companyName)
        );
      }
      if (this.selectedEventSpeakers.includes(person)) {
        this.$store.dispatch('event/removeSelectedEventSpeaker', person);
        this.allUsers.splice(0, 0, person);
      }
    },
    backgroundImage() {
      return backgroundImage;
    },

    async updateEventData() {
      this.loading = true;
      this.eventForm.event_speakers.map((e) => {
        delete e.id;
      });

      console.log('Fragerium ==>', this.eventForm);
      const formData = {
        ...this.eventForm,
        companyProfileId: this.user.lastAccessedCompanyId,
        thumb_nail: this.imageUrl,
        eventId: this.eventForm.eventId,
        start_date: this.$moment(this.eventForm.start_date).format(
          'YYYY-MM-DD'
        ),
        end_date: this.$moment(this.eventForm.end_date).format('YYYY-MM-DD'),
      };
      delete formData.event_speakers;
      delete formData.posts;
      delete formData.id;
      delete formData.creator;
      delete formData.__typename;

      if (this.eventForm.type === 'VIRTUAL') {
        formData.meeting_link = this.eventForm.meeting_link;
      }

      this.getSelectedEventSpeakers().map((spk) => {
        console.log("Xaxy=<", spk)
        spk.eventId = this.eventForm.id;
      });

      this.eventForm.event_speakers.map((spk) => {
        delete spk.__typename;
        spk.eventId = this.eventForm.id;
      });
      console.log('going==>', this.getSelectedEventSpeakers());
      console.log('goingx==>', this.eventForm.event_speakers);

      updateEvent({
        eventId: this.eventForm.id,
        updateEventObj: formData,
        newSpeaker: [
          ...this.getSelectedEventSpeakers(),
          ...this.eventSpeakerNotOnVB,
        ],
      })
        .then(() => {
          this.loading = false;
          this.isEventModalOpen = false;
          this.$toast({
            title: 'You have successfully Updated this event',
            description: `Successfully left an event`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.eventForm = {
            name: '',
            start_time: '00:00 AM',
            description: '',
            type: 'PHYSICAL',
            end_time: '00:00 PM',
            start_date: '',
            end_date: '',
            event_speakers: [],
            meeting_link: '',
            time_zone: '',
          };
        })
        .catch((e) => {
          this.$toast({
            title: 'There was an error trying to update this event',
            description: `${e}`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
          this.loading = false;
        });
    },

    async onEventFormSubmit() {
      this.eventForm.event_speakers.map((e) => {
        delete e.id;
      });

      console.log("Fruxilixty =>", this.getSelectedEventSpeakers())

      this.isCreatingEvent = true;
      const formData = {
        ...this.eventForm,
        event_speakers: {
          data: [
            ...this.getSelectedEventSpeakers(),
            ...this.eventForm.event_speakers,
          ],
        },
        companyProfileId: this.user.lastAccessedCompanyId,
        thumb_nail: this.imageUrl,
        start_date: this.$moment(this.eventForm.start_date).format(
          'YYYY-MM-DD'
        ),
        end_date: this.$moment(this.eventForm.end_date).format('YYYY-MM-DD'),
      };

      if (this.eventForm.type === 'VIRTUAL') {
        formData.meeting_link = this.eventForm.meeting_link;
      }

      try {
        // await createEvent( formData )
        await this.$store.dispatch('event/saveEvent', formData);

        this.isCreatingEvent = false;
        this.closeCreateEventModal();
        await this.$store.dispatch('event/resetSelectedEventSpeakers');

        if (this.$route.path !== '/explore') {
          this.$router.push('/app/explore').then(() => {
            EventBus.$emit('open-post-modal');
          });
        }

        EventBus.$emit('open-post-modal');

        // this.$toast({
        //   title: 'Event successfully created',
        //   description: `Event has been successfully created`,
        //   status: 'success',
        //   position: 'top',
        //   duration: 3000,
        // });
        this.eventForm = {
          name: '',
          start_time: '00:00 AM',
          description: '',
          type: 'PHYSICAL',
          end_time: '00:00 PM',
          start_date: '',
          end_date: '',
          event_speakers: [],
          meeting_link: '',
          time_zone: '',
        };
      } catch (e) {
        console.log(e);
        this.isCreatingEvent = false;
        this.$toast({
          title: 'An error occurred.',
          description: `Error while saving event`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
    async getUsersMatchingQuery() {
      const response = await searchUsers({
        search_param: '%' + this.query + '%',
        offset: 0,
        limit: 7,
      });

      const users = response.data.user_search;
      //  get only usrs who have firstname and lastname and companies array has data
      // limit to 5 users

      this.allUsers = users.filter((user) => {
          return (
            (user.firstname && user.lastname && user.companyName) &&

           (this.selectedEventSpeakers.some((person) => {
              console.log("Usex", person.user.id !== user.id);
              console.log("Persox", person.companyName != user.companyName)
              return (person.user.id !== user.id) && (person.companyName != user.companyName)
            }))
           
          );
        });

      // this.allUsers = users.filter(
      //   (user) => user.firstname && user.lastname && user.companyName
      // );
      this.loading = false;
    },
    search: debounce(async function (searchQuery) {
      if (!searchQuery) {
        await this.getUsers();
        return;
      }

      // Replace with your actual search logic/API call
      await this.getUsersMatchingQuery();
    }, 400), // 300ms
    handleInput(event) {
      if (event.target) {
        this.query = event.target.value;
        this.search(this.query);
      }
    },
    addSpeaker() {
      if (this.editing) {
        console.log(this.speakerForm);
        this.eventForm.event_speakers.push({
          id: this.generateRandomId(),
          firstName: this.speakerForm.firstName,
          lastName: this.speakerForm.lastName,
          companyName: this.speakerForm.companyName,
          position: this.speakerForm.position,
          eventId: this.eventForm.id, 
        });
        this.speakerForm = {};
      } else {
        this.eventForm.event_speakers.push({
          id: this.generateRandomId(),
          firstName: this.speakerForm.firstName,
          lastName: this.speakerForm.lastName,
          companyName: this.speakerForm.companyName,
          position: this.speakerForm.position,
        });
        this.speakerForm = {};
      }
    },

    getSelectedEventSpeakers() {
      if (this.editing) {
        return this.selectedEventSpeakers.map((speaker) => {
          console.log("Faraxe=>", speaker);
          return {
            userId: speaker.user ? speaker.user.id : speaker.id,
            firstName: speaker.firstname ? speaker.firstname : speaker.firstName,
            lastName: speaker.lastname ? speaker.lastname : speaker.lastName,
            eventId: this.eventForm.id,
            companyName: speaker.companyName,
          };
        });
      } else {
        return this.selectedEventSpeakers.map((speaker) => {
          console.log('Speaker=>', speaker);
          return {
            userId: speaker.user ? speaker.user.id : speaker.id,
            firstName: speaker.firstname,
            lastName: speaker.lastname,
            companyName: speaker.companyName
          };
        });
      }
    },
    removeSpeaker(item) {
      this.eventForm.event_speakers = this.eventForm.event_speakers.filter(
        (speaker) => speaker.id !== item.id
      );
    },
    async getUsers() {
      try {
        const response = await searchUsers({
          search_param: '%',
          offset: 0,
          limit: 15,
        });

        const users = response.data.user_search;

        //  get only usrs who have firstname and lastname and companies array has data
        // limit to 5 users
        this.allUsers = users.filter(
          (user) => user.firstname && user.lastname && user.companyName
        );

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    generateRandomId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
    close() {
      this.isEventModalOpen = false;
    },
    openFileInput() {
      // Trigger click event of file input when div is clicked
      this.$refs.fileInput.click();
    },
    async onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        this.isUploadingImage = true;
        this.imageUrl = null;
        this.eventForm.thumb_nail = null;
        // Generate a preview URL immediately
        const reader = new FileReader();
        reader.onload = (event) => {
          console.log(event);
          // this.imageUrl = event.target.result; // Set the preview URL
          // this.eventForm.thumb_nail = null;
        };
        reader.readAsDataURL(file);

        try {
          // Upload the file to the server
          const res = await uploadFile({ file, type: 'image' });
          console.log('Image URL after upload:', res);
          this.imageUrl = res.data.url; // Update with the uploaded image URL
          this.eventForm.thumb_nail = res.data.url;
        } catch (error) {
          console.error(error);
          this.$toast({
            title: 'An error occurred.',
            description: 'Error while uploading event banner.',
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        } finally {
          this.isUploadingImage = false;
        }
      }
    },

    // openSpeakerModal(){
    //   EventBus.$emit('open-speakers-modal')
    // },
    openCreateEventModal() {
      this.isEventModalOpen = true;
      this.eventForm = {
        name: '',
        start_time: '00:00 AM',
        description: '',
        type: 'PHYSICAL',
        end_time: '00:00 PM',
        start_date: '',
        end_date: '',
        event_speakers: [],
        meeting_link: '',
        time_zone: '',
      };
    },
    closeCreateEventModal() {
      this.isEventModalOpen = false;
      this.editing = false;
      EventBus.$emit('open-post-modal');
    },
  },
  created() {
    EventBus.$on('open-modal', this.openCreateEventModal);
    EventBus.$on('open-event-edit-modal', (data) => {
      this.isEventModalOpen = true;
      // this.editing = true;
      this.$store.dispatch('event/resetSelectedEventSpeakers');
      this.eventForm = data;
      this.eventForm.event_speakers.map((ev) => {
        if (ev.user) {
          console.log('HV', ev);
          // this.selectedEventSpeakers.push(ev.user);
          this.selected(ev);
          this.allUsers = this.allUsers.filter((usr) => {
            return (
              (usr.id + usr.companyName) !== (ev.user.id + ev.companyName)
            );
          });
        }
      });
      // this.eventForm.event_speakers = this.eventForm.event_speakers.filter((ev) => !ev.user)
      this.editing = true;
    });
    EventBus.$on('close-modal', this.closeCreateEventModal);
  },

  beforeDestroy() {
    EventBus.$off('open-modal', this.openCreateEventModal);
    EventBus.$off('close-modal', this.closeCreateEventModal);
  },
};
</script>

<style lang="scss">
.modal-contents {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.vue__time-picker input.display-time {
  border: none !important;
  background-color: #c7d6f466;
  border-radius: 5px;
  height: 40px !important;
  font-family: Montserrat, serif;
  font-size: 20px;
  width: 100%;
  color: rgb(144, 147, 149);
}

.time-picker-style {
  height: 40px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-gap: 20px; /* Adjust the gap between grid items as needed */
}

.grid-item-event {
  width: 100%; /* Ensure grid items take up full width of their grid cell */
}

.form-labels {
  font-weight: 500;
  color: #002b49;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: orangered !important;
}

.css-e2zh0b {
  background-color: #c7d6f466;
}

.vc-icon-calendar {
  margin-top: 12px;
}
.vc-icon-clear {
  margin-top: 12px;
}
</style>
