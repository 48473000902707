<template>
  <c-flex my="4" justify="space-between" align="center">
    <c-flex align="center">
      <img
          v-if="person.profilePhoto"
          alt="user-avatar"
          class="user-avatar"
          :src="person.profilePhoto"
      />
      <img v-else alt="user-avatar" width="45px" src="@/assets/img/user.jpg" />
      <c-box ml="4" color="gray.700" style="cursor: pointer">
        <c-text flex="column" fontSize="14px" fontWeight="700"
          >{{ person.firstname }} {{ person.lastname }}</c-text
        >

        <c-text fontSize="13px" fontWeight="400">{{
          person.companyName
        }}</c-text>
      </c-box>
    </c-flex>

<!--    <c-flex justify="end">-->
<!--      <c-button-->
<!--          variant-color="vc-orange"-->
<!--          variant="outline"-->
<!--          size="sm"-->
<!--          border="1px"-->
<!--          :is-loading="btnLoading"-->
<!--          @click="follow"-->
<!--      >-->
<!--        {{ followed ? 'Following' : 'Follow' }}-->
<!--      </c-button>-->
<!--    </c-flex>-->
  </c-flex>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'PeopleCard',
  props: ['person'],
  computed: {
    ...mapGetters({
      selectedTaggedUsers: 'explore/getSelectedTaggedUsers',
    }),
  },
  data() {
    return {
      btnLoading: false,
    };
  },
  methods: {
    goToProfile(userId) {
      console.log(userId);
      this.$router.push({ name: 'Profile', params: { userId } });
    },

  },
};
</script>

<style scoped>
button:hover {
  background-color: #e5724e;
  color: #fff;
}
.user-avatar {
  border-radius: 10%;
  height: 45px;
  width: 45px;
}
</style>
