import { render, staticRenderFns } from "./EventModal.vue?vue&type=template&id=0587f184"
import script from "./EventModal.vue?vue&type=script&lang=js"
export * from "./EventModal.vue?vue&type=script&lang=js"
import style0 from "./EventModal.vue?vue&type=style&index=0&id=0587f184&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CImage: require('@chakra-ui/vue').CImage, CIcon: require('@chakra-ui/vue').CIcon, CButton: require('@chakra-ui/vue').CButton, CSpinner: require('@chakra-ui/vue').CSpinner, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CRadio: require('@chakra-ui/vue').CRadio, CRadioGroup: require('@chakra-ui/vue').CRadioGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CInput: require('@chakra-ui/vue').CInput, CTextarea: require('@chakra-ui/vue').CTextarea, CSwitch: require('@chakra-ui/vue').CSwitch, CIconButton: require('@chakra-ui/vue').CIconButton, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
